.p-container {
    display: flex;
}

/* image */
.p-image-container {
    width: 450px;
    height: 450px;
    border: 1px solid #ddd;
    margin-bottom: 30px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
}

.p-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    cursor: pointer;
}

/* details */
.p-details {
    flex: 1;
}

.p-name {
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
}

.p-price {
    color: #11468F;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 20px;
}

.p-brand {
    font-size: 14px;
    margin-top: 20px;
}

.p-brand-name {
    color: #11468F;
}

.p-description {
    margin-top: 20px;
    font-size: 16px;
    color: gray;
}

/* add to cart */
.p-add-cart-container {
    display: flex;
    align-items: center;
    margin: 20px 0px;
    gap: 20px;
}

.p-quantity-container {
    border: 1px solid #ddd;
    border-radius: 50px;
    /* margin-right: 20px; */
    padding: 0px 2px;
    display: flex;
    align-items: center;
}

.p-add-cart-button {
    background-color: #DD4A48;
    border: 1px solid #DD4A48;
    border-radius: 30px;
    padding: .5rem 1rem;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
}

.quantity-button {
    cursor: pointer;
    border-radius: 50px;
    border: 0px;
    background-color: white;
    padding: .5rem;
}

.p-quantity {
    margin: 0px 10px;
    font-size: 1rem;
}


/* large screens */
@media screen and (max-width: 992px) {
    .p-image-container {
        width: 400px;
        height: 400px;
    }

    .p-name {
        font-size: 1.3rem;
    }
}

/* medium screens */
@media screen and (max-width: 820px) {
    .p-image-container {
        width: 330px;
        height: 330px;
        margin-right: 1rem;
        margin-bottom: 1rem;
    }

    .p-name {
        font-size: 1.1rem;
    }

    .p-price {
        margin-top: 1rem;
    }

    .p-brand {
        margin-top: 1rem;
    }

    .p-description {
        margin-top: 1rem;
    }

    /* add to cart */
    .p-add-cart-container {
        margin: 1rem 0px;
        gap: 1rem;
    }

    .p-add-cart-button {
        padding: .45rem .9rem;
        color: white;
        font-size: .9rem;
    }
    .quantity-button {
        padding: .3rem;
    }
}

/* Extra small devices (phones, 600px and down) */
@media screen and (max-width: 600px) {
    .p-container {
        flex-direction: column;
    }

    .p-image-container {
        margin-right: 0;
        align-self: center;
    }
}