.main-sidebar {
    width: 25%;
    margin-right: 1.5rem;
    margin-left: 1rem;
}

.sidebar-header-container {
    border-bottom: 1px solid #ccc;
    /* disabel text selection */
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    /*
      Introduced in Internet Explorer 10.
      See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
    */
    -ms-user-select: none;
    user-select: none;
}

.sidebar-header-container h4 {
    display: block;
    padding: 10px 0;
    color: inherit;
}

.category-arrow {
    display: none;
    width: 1rem;
    height: 1rem;
    fill: inherit;
}

.categories-list {
    padding: 10px 15px;
}

.categories-list li {
    display: block;
    border-bottom: 1px dotted #ddd;
    line-height: 40px;
    font-size: 14px;
    font-weight: 600;
    margin-right: 15px;
}

.categories-list li a {
    display: block;
    cursor: pointer;
    white-space: nowrap;
}


/* medium screens */
@media screen and (max-width: 820px) {
    .main-sidebar {
        margin-right: 1rem;
    }

    .categories-list {
        padding: .4rem .8rem;
    }
}

/* medium small screens */
@media screen and (max-width: 768px) {
    .main-sidebar {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    .sidebar-header-container h4 {
        font-weight: 600;
    }
    .category-arrow {
        display: block;
    }

    .categories-list {
        display: none;
        background-color: white;
        padding: 0 15px;
        box-shadow: 0px 5px 10px -5px grey;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;
    }

    .categories-list li:last-child {
        border-bottom: 1px solid white;
    }

    .sidebar-header-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px .5rem;
        --backgorund-color: var(--blue-light);
        background-color: var(--backgorund-color);
        border-bottom: 1px solid var(--backgorund-color);
        border-radius: .5rem;
    }

    .sidebar-header-container.active{
        --backgorund-color: var(--blue);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        color: white;
        fill: white;
    }
    .categories-list.active{
        display: block;
    }
}