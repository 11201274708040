.container-bread {
    background-color: var(--gray);
    padding: 20px 15px;
    margin-bottom: 30px;
}

.container-bread {
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
}

a {
    text-decoration: none;
}

.current-bread {
    display: inline-block;
    color: var(--blue);
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.current-bread::before {
    padding: 0 5px;
    color: #ccc;
    content: "/\00a0";
}


/* medium screens */
@media screen and (max-width: 820px) {
    .container-bread {
        margin-bottom: 1rem;
        padding: .8rem 1rem;
    }
}