.cart-table {
    border: 1px solid #ddd;
    border-collapse: collapse;
}

.cart-image {
    --size: 80px;
    min-width: var(--size);
    width: var(--size);
    height: var(--size);
    border-radius: .3rem;
    object-fit: cover;
}

table {
    width: 100%;
    margin: 20px 0px;
}

thead {
    background-color: var(--blue-light);
}

td, th {
    border: 1px solid var(--border);
    text-align: left;
    padding: .5rem;
    text-align: center;
    width: 10%;
}

.item-cart {
    width: 40%;
}

.item-cart-container {
    display: flex;
    align-items: center;
}

.item-cart-container-sub {
    margin: 0px 1rem;
    text-align: start;
}

.cart-item-price {
    margin-top: .2rem;
    color: var(--red);
    font-size: .8rem;
}

.cart-item-price::before {
    content: 'Unit Price: ';
    color: var(--grey-text);
}

.cart-delete {
    cursor: pointer;
    padding: 4px;
    background-color: white;
    border: 0px;
}

.empty-cart {
    color: gray;
    margin: 20px 0px;
    /* text-align: center; */
}

.cart-qunatity-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 0px;
}

.quantity-action {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.quantity {
    margin: 0px 8px;
}

/* details */
.cart-sub-container {
    display: flex;
    justify-content: space-between;
    margin: 30px 0px;
    position: relative;
}

.cart-user-details {
    flex: 0.35;
}

.cart-user-details h3 {
    background-color: var(--gray);
    border: 1px solid #ddd;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 0px;
    padding: 0.5rem 1rem;
}

.cart-form {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 1rem;
    padding-top: 0;
}

.cart-form label {
    padding: 10px 0px;
}

.form-input {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: .5rem .8rem;
}

.location-container {
    display: flex;
    align-items: center;
}

.location-text {
    flex: 1;
}

.location-button {
    background-color: white;
    border: 0px;
    cursor: pointer;
    padding-left: 0.5rem;
}

.place-order {
    background-color: var(--blue);
    border: 0px;
    padding: 12px;
    border-radius: 6px;
    color: white;
    margin-top: 20px;
    font-size: 14px;
    cursor: pointer;
}

/* summary */
.summary-container {
    flex: 0.2;
}

.summary-container .sub {
    border: 1px solid #ddd;
}

.summary-container h3 {
    padding: 0.5rem 1rem;
}

.summary-row {
    display: flex;
    border-top: 1px solid #ddd;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    font-size: 14px;
}

.summary-row span {
    white-space: nowrap;
}

.sub-total {
    color: var(--blue);
    font-weight: 600;
    font-size: 1rem;
    white-space: nowrap;
}

/* large screens */
@media screen and (max-width: 992px) {
    .cart-title {
        font-size: 1.6rem;
    }
}

/* medium screens */
@media screen and (max-width: 820px) {
    table {
        margin: 1rem 0px;
    }

    td, th {
        padding: .35rem;
    }

    .item-cart {
        width: 50%;
    }

    .cart-image {
        --size: 60px;
    }

    .cart-sub-container {
        margin: 1.3rem 0;
    }
}

/* Extra small devices (phones, 600px and down) */
@media screen and (max-width: 600px) {
    .cart-title {
        font-size: 1.4rem;
    }

    .item-cart {
        width: 40%;
    }

    .item-cart-container {
        flex-direction: column;
    }

    .item-cart-container-sub {
        margin: 0px .3rem;
        margin-top: .5rem;
        text-align: center;
    }

    .cart-item-name {
        font-size: .9rem;
    }

    /* details */
    .cart-sub-container {
        flex-direction: column;
    }

    /* summary */
    .summary-container {
        position: absolute;
        left: 0;
        right: 0;
    }
    /* user  details */
    .cart-user-details {
        flex: 0.35;
        margin-top: 90px;
    }
    
}