.image-modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}
.image-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000099;
}
/* .image-container{
    padding: 1rem;
    width: 100%;
    height: 100%;
} */
.full-image{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
.close-image{
    position: absolute;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    top: 1rem;
    right: 1rem;
    z-index: 1;
}