.container-product {
    display: flex;
    box-sizing: border-box;
    /* background-color: aqua; */
}

.main {
    width: 75%;
    box-sizing: border-box;
}

.title {
    margin-bottom: 20px;
}

.empty-container {
    color: gray;
    padding: 20px;
    text-align: center;
}

/* medium screens */
@media screen and (max-width: 820px) {
    .title {
        margin-bottom: 1rem;
        font-size: 1.4rem;
    }
}

/* medium small screens */
@media screen and (max-width: 768px) {
    .container-product {
        flex-direction: column;
    }

    .main {
        width: 100%;
    }
    .title {
        margin: 1rem 0px;
        text-align: center;
    }
}