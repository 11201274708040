.footer-container{
    background-color: #11468F;
}
.grid-footer{
    display: grid;
    grid-template-columns: auto auto;
    padding: 1rem;
    box-sizing: border-box;
}
.footer-line{
    border: 0px;
    border-top: 1px solid white;
}
.footer-bottom{
    padding: 1rem;
    box-sizing: border-box;
}
.copyright{
    font-size: 14px;
    color: white;
}
.copyright span{
    font-size: inherit;
    color: #D8D2CB;
}
.footer-heading{
    color: #D8D2CB;
    margin-left: 3px;
    margin-bottom: 1rem;
}

/* social button */
.social-container{
    display:inline-flex;
    align-items: center;
    margin-bottom: .5rem;
}
.social-image{
    width: 30px;
}
.social-text{
    font-size: .88rem;
    color: white;
    margin-left: .5rem;
}

/* address */
.address-row{
    display: flex;
    align-items: center;
    margin-bottom: .5rem;
    /* padding: 2px; */
}
.address-row span{
    font-size: .88rem;
    color: white;
    margin-left: .5rem;
}
.footer-contact-icon{
    box-sizing: border-box;
    fill: #889EAF;
    width: 30px;
    height: 30px;
    padding: 1px;
}

/* Extra small devices (phones, 600px and down) */
@media screen and (max-width: 500px) {
    .grid-footer{
        display: flex;
        padding: .5rem;
        gap: .5rem;
        flex-direction: column;
    }
}
