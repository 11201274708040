.product-list {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    gap: 1rem 2%;
    box-sizing: border-box;
}

.product-item {
    text-align: center;
    box-sizing: border-box;
}

.product-item img {
    width: 100%;
    height: 260px;
    object-fit: cover;
}

.product-name {
    font-size: 18px;
    font-weight: 400;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-price {
    color: #11468F;
    font-size: 1rem;
    font-weight: 500;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.product-item button {
    background-color: white;
    border: 2px solid #FF9999;
    border-radius: 30px;
    padding: .5rem 1rem;
    margin: 20px 0px;
    color: #DD4A48;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
}

.load-more {
    background-color: white;
    border: 2px solid #11468F;
    border-radius: 10px;
    padding: 10px 20px;
    display: block;
    margin: 30px auto;
    color: #11468F;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

/* hover */
.product-item:hover {
    box-shadow: 2px 2px 20px #D1D1D1;
}

.product-item button:hover {
    background-color: #DD4A48;
    border: 2px solid #DD4A48;
    color: white;
}


/* large screens */
@media screen and (max-width: 992px) {
    .product-item img {
        height: 240px;
    }
}

/* medium screens */
@media screen and (max-width: 820px) {
    .product-item img {
        height: 220px;
    }
    .product-name {
        font-size: 1rem;
        margin-top: 1rem;
    }

    .product-price {
        font-size: .9rem;
        margin-top: 1rem;
    }

    .product-item button {
        padding: .45rem .9rem;
        margin: 1rem 0px;
        font-size: .9rem;
    }
}

/* small screens */
@media screen and (max-width: 768px) {
    .product-item img {
        height: 260px;
    }
}

/* smaller screens */
@media screen and (max-width: 600px) {
    .product-list {
        grid-template-columns: 180px 180px;
        justify-content: center;
        gap: 1rem;
    }
    .product-item img {
        height: 180px;
    }
}

/* extra smaller screens */
@media screen and (max-width: 410px) {
    .product-list {
        grid-template-columns: 160px 160px;
    }
    .product-item img {
        height: 160px;
    }
}