.loader-container {
  display: flex;
  justify-content: center;
  padding: 16px;
}

.loader {
  border: 10px solid #F1F1F1;
  border-radius: 50%;
  border-top: 10px solid var(--blue);
  border-bottom: 10px solid var(--red);
  /* border-left: 10px solid #11468F;
    border-right: 10px solid #DD4A48; */
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* full screen laoder */
.loading-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}