.contact-container {
    display: flex;
    margin: 1rem 0px;
    gap: 1rem;
}

.contact-social-div {
    flex: 0.7;
}

.contact-info-div {
    flex: 0.3;
    padding-left: .5rem;
}

.contact-title {
    margin: 1rem 0px;
}

.contact-info-row {
    display: flex;
    margin-top: 2.3rem;
}

.info-content {
    margin-left: 2rem;
}

.info-title {
    font-weight: 700;
    font-size: 1rem;
    white-space: nowrap;
}

.info-desc {
    font-size: 0.95rem;
    margin-top: 0.5rem;
}

/* social */
.contact-social-button{
    display:inline-flex;
    align-items: center;
    margin-top: 2rem;
}
.contact-social-text{
    font-size: 0.95rem;
    margin-left: 8px;
}

/* small screens */
@media screen and (max-width: 600px) {
    .contact-container{
        flex-direction: column;
    }
    .contact-title {
        font-size: 1.3rem;
    }
    .contact-sub-title{
        font-size: 1rem;
    }
    .contact-info-row {
        margin-top: 1.5rem;
    }
    .info-content {
        margin-left: 1rem;
    }
    .info-title {
        font-size: .9rem;
    }
    .info-desc {
        font-size: 0.9rem;
    }
    .contact-social-button{
        margin-top: 1rem;
    }
}