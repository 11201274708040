.search-box {
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.search-input {
    width: 100%;
    padding: 10px 16px;
    margin-bottom: 1rem;
    border: none;
    background-color: var(--gray);
    border-radius: 100px;
    box-sizing: border-box;
    outline: none;
}

.search-data {
    width: calc(100% - 2rem);
    max-height: 300px;
    display: none;
    position: absolute;
    background-color: white;
    border-top: 0;
    left: 1rem;
    right: 0;
    top: calc(20px + 18px);
    box-shadow: 0.5px 0.5px 10px -4px grey;
    z-index: 1;
    padding: .5rem 0px;
    padding-bottom: 0px;
    overflow: hidden;
    overflow-y: scroll;
}

.search-row {
    display: flex;
    align-items: center;
    padding: .5rem 1rem;
}

.search-image {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: .2rem;
}

.search-text {
    width: calc(100% - 40px - 1rem);
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 1rem;
}

/* pseudo classes */
.search-row:hover {
    background-color: var(--gray);
}

.search-input:focus+.search-data {
    display: block;
    outline: none;
}

.search-data:hover {
    display: block;
}

/* small screens */
@media screen and (max-width: 600px) {
    .search-row {
        padding: .3rem .8rem;
    }

    .search-input {
        margin-bottom: .5rem;
    }
}