.app-container {
  min-height: 64vh;
  position: relative;
}

.container {
  /* width: 1140px; */
  width: clamp(750px, 85%, 1100px);
  margin: auto;
  /* position: relative; */
}

/* responsive style */

/* medium screens */
@media screen and (max-width: 768px) {
  .container {
    width: clamp(600px, 95%, 750px);
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 620px) {
  .container {
    width: clamp(346px, 95%, 620px);
  }
}

/* @media screen and (max-width: 1200px) {
  .container {
    width: 970px;
  }
}

@media screen and (max-width: 1000px) {
  .container {
    width: 850px;
  }
} */