header {
    display: grid;
    grid-template-columns: 1fr 2fr;
    justify-content: space-between;
}

.app-name {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.app-name img {
    width: 80px;
}

.app-name h2 {
    color: var(--red);
    text-align: center;
    margin-left: 1rem;
    white-space: nowrap;
}

.nav-container {
    padding: 1rem;
    display: flex;
}

.nav-inner {
    flex: 1;
}

.nav-item {
    display: inline-block;
    padding: .5rem 0;
    margin-right: 1rem;
}

.nav-link {
    text-decoration: none;
    font-size: 1.15rem;
}

.nav-name {
    white-space: nowrap;
    font-weight: bold;
    color: inherit;
}
/* for prescription */
.order-now{
    background-color: var(--red);
    padding: .5rem .7rem;
    border-radius: .5rem;
    color: white;
}
.order-now:hover{
    background-color: var(--gray);
    color: var(--red);
}

.nav-dropdown-name {
    align-items: center;
    display: inline-flex;
}

.down-arrow {
    width: 1.5rem;
    height: 1.5rem;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;

    /*
      Introduced in Internet Explorer 10.
      See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
    */
    -ms-user-select: none;
    user-select: none;
}

.cart-button {
    position: relative;
    text-align: center;
    margin: 0px 1rem;
    text-decoration: none;
    align-self: center;
    white-space: nowrap;
}

.cart-total {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--red);
    color: white;
    height: 16px;
    width: 16px;
    border-radius: 50px;
    font-size: 12px;
    padding: 2px;
}

.cart-icon {
    fill: var(--blue);
    width: 25px;
    height: 25px;
}

/* div category styles */
.div-category {
    display: none;
    position: absolute;
    background-color: white;
    padding: 1rem;
    box-shadow: 1px 1px 5px #D1D1D1;
    z-index: 1;
}

.div-category h4 {
    display: inline-block;
    border-bottom: 1px solid #ddd;
    padding-bottom: .5rem;
}

.categories {
    display: grid;
    margin-top: .5rem;
    margin-left: .5rem;
    grid-template-columns: auto auto;
    gap: .5rem;
}

.categories a span {
    display: inline-block;
    font-size: .95rem;
    font-weight: 500;
    padding: 4px;
    white-space: nowrap;
}

.categories a span:hover {
    color: var(--red);
}

.nav-name:hover {
    color: var(--red);
}

/* large screens */
@media screen and (max-width: 992px) {
    .app-name img {
        width: 70px;
    }

    .app-name h2 {
        font-size: 1.2rem;
    }

    .nav-link {
        font-size: 1rem;
    }

    .down-arrow {
        width: 1.3rem;
        height: 1.3rem;
    }
}

@media screen and (min-width: 820px) {

    /* hover */
    .dropdown:hover .div-category {
        display: block;
    }
}

/* medium screens */
@media screen and (max-width: 820px) {

    header {
        /* flex-direction: column; */
        grid-template-columns: 1fr;
    }

    .app-name {
        margin-top: .5rem;
        margin-left: .5rem;
    }

    .app-name img {
        width: 60px;
    }

    .cart-button {
        position: absolute;
        top: 1rem;
        right: 0;
    }

    .cart-total {
        font-size: .6rem;
        height: .8rem;
        width: .8rem;
    }

    /* sub category */
    .div-category {
        margin-top: .5rem;
        padding: .5rem;
    }

    .div-category h4 {
        font-size: .9rem;
    }

    .div-category.active {
        display: block;
    }

    .categories {
        grid-template-columns: auto auto;
    }

    .categories a span {
        font-size: .85rem;
    }
}

/* Extra small devices (phones, 600px and down) */
@media screen and (max-width: 600px) {
    .app-name img {
        width: 50px;
    }

    .app-name h2 {
        font-size: 1rem;
        margin-left: .5rem;
    }

    .cart-icon {
        width: 22px;
        height: 22px;
    }

    .cart-button h5 {
        font-size: .7rem;
    }
}
/* Extra extra small devices (phones, 450px and down) */
@media screen and (max-width: 450px) {
    .nav-item{
        position: relative;
    }
    .div-category {
        right: -1rem;
    }
}