.error-container{
    background-color: var(--error);
    gap: 1rem;
    padding: .6rem 1rem;
    text-align: center;
    margin: 1rem;
    border-radius: .5rem;
}
.error-detail{
    color: white;
    font-size: .9rem;
}