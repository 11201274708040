.container-new {
    background-color: var(--gray);
    padding-bottom: 20px;
    box-sizing: border-box;
}

.title-new {
    text-align: center;
    padding: 40px;
}

/* nal for new arrival list */
.nal-container {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(5, 300px);
    gap: 1rem 0rem;
    overflow: scroll;
}

.nal-item {
    margin-right: 20px;
    text-align: center;
    background-color: white;
}

/* .nal-image-container{
    width: 100%;
    height: 300px;
} */
.nal-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.nal-product-name {
    font-size: 18px;
    font-weight: 400;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.nal-product-price {
    color: #11468F;
    font-size: 1rem;
    font-weight: 500;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.nal-cart-button {
    background-color: white;
    border: 2px solid #FF9999;
    border-radius: 30px;
    padding: 10px 20px;
    margin: 20px 0px;
    color: #DD4A48;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
}

/* hover */
.nal-cart-button:hover {
    background-color: #DD4A48;
    border: 2px solid #DD4A48;
    color: white;
}

/* responsive style */
/* large screens */
@media screen and (max-width: 992px) {
    .title-new {
        padding: 20px;
        font-size: 1.6rem;
    }

    .container-new {
        padding-bottom: .9rem;
    }

    .nal-container {
        grid-template-columns: repeat(5, 250px);
        gap: .9rem 0rem;
    }

    .nal-item {
        margin-right: .9rem;
    }

    .nal-image {
        height: 250px;
    }

    .nal-product-name {
        font-size: 1rem;
        margin-top: .9rem;
    }

    .nal-product-price {
        font-size: .9rem;
        margin-top: .9rem;
    }

    .nal-cart-button {
        margin: .5rem 0px .9rem 0px;
        font-size: .9rem;
        padding: .5rem 1rem;
    }
}

/* medium screens */
@media screen and (max-width: 820px) {

    /* .container-new {
        padding-bottom: .5rem;
    } */
    .nal-container {
        grid-template-columns: repeat(5, 200px);
    }
/* 
    .nal-item {
        margin-right: .5rem;
    } */

    .nal-image {
        height: 200px;
    }

    .nal-product-name {
        margin-top: .5rem;
    }

    .nal-product-price {
        margin-top: .5rem;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .nal-image {
        height: 170px;
    }
}