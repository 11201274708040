/* da for download app */
.da-container {
    display: flex;
    margin-top: .5rem;
    margin-bottom: .5rem;
    /* background-color: #423F3E; */
}

.mockup {
    width: 300px;
    height: 300px;
    object-fit: contain;
}

.mockup-content {
    margin: 30px;
}

/* .mockup-content .heading{
    
} */
.mockup-content .desc {
    margin-top: 4px;
    font-weight: 500;
    color: #423F3E;
}

.download-button {
    display: flex;
}

.download-button .img {
    margin-top: 20px;
    padding-right: 12px;
    width: 150px;
    height: 45px;
}

/* responsive style */
/* large screens */
@media screen and (max-width: 992px) {
    .mockup {
        width: 250px;
        height: 250px;
    }

    .mockup-content {
        margin: 20px;
    }

    .mockup-content .heading {
        font-size: 1.6rem;
    }

    .download-button .img {
        width: 130px;
        height: 40px;
    }
}

/* small screens */
@media screen and (max-width: 768px) {
    .mockup {
        width: 200px;
        height: 200px;
    }

    .mockup-content {
        margin: 1rem;
    }

    .mockup-content .heading {
        font-size: 1.3rem;
    }

    .download-button .img {
        width: 110px;
        height: 35px;
        margin-top: .9rem;
    }
}

/* Extra small devices (phones, 600px and down) */
@media screen and (max-width: 600px) {
    .mockup {
        width: 180px;
        height: 180px;
    }

    .mockup-content {
        margin: .5rem;
    }

    .mockup-content .heading {
        font-size: 1.1rem;
    }
    .download-button {
        flex-direction: column;
    }
    .download-button .img {
        width: 95px;
        height: 30px;
        margin-top: .3rem;
    }
}