.slideshow-container {
    position: relative;
    /* max-width: 1300px; */
    min-height: 100px;
    /* background-color: var(--gray); */
    /* text-align: center; */
    display: flex;
    justify-content: center;
}

.mySlides {
    display: none;
    max-width: 1300px;
}

.slideActive {
    display: block;
}

.image {
    width: 100%;
    object-fit: contain;
}

/* Caption text */
/* .text {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
} */

/* The dots/bullets/indicators */
.dot-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 8px;
}

.dot {
    cursor: pointer;
    height: 12px;
    width: 12px;
    margin: 0 3px;
    background-color: #f6f7fb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.activeDot, .dot:hover {
    background-color: var(--grey-text);
}

/* Fading animation */
.fade{
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
}

@-webkit-keyframes fade {
    from {
        opacity: .4
    }

    to {
        opacity: 1
    }
}

@keyframes fade {
    from {
        opacity: .4
    }

    to {
        opacity: 1
    }
}

/* responsive style */

/* Extra small devices (phones, 600px and down) */
@media screen and (max-width: 600px) {
    .dot {
        height: .55rem;
        width: .55rem;
    }
}