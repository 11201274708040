.prescription-container {
    padding-right: 1rem;
    box-sizing: border-box;
}

.prescription-container .desc {
    color: gray;
    font-weight: 500;
    font-size: 1rem;
}

.presc-form-row {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    position: relative;
}

.prescription-label {
    flex: .3;
    white-space: nowrap;
}

.presc-form-input {
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-right: 1rem;
    flex: .7;
    min-height: 35px;
    padding: 0px 0.5rem;
    /* hide arrow buttons for number */
    -moz-appearance: textfield;
    outline: none;
}

.presc-form-input::-webkit-inner-spin-button {
    -webkit-appearance: none;

}

.text-area {
    border: 0;
    width: 100%;
    resize: none;
    display: block;
    outline: none;
    padding: .5rem 0;
    border-radius: 4px;
}

.upload-image-container {
    flex: .7;
    display: flex;
    align-items: center;
    margin-right: 1rem;
    /* padding: 0px 0.5rem; */
}

.upload-button {
    background-color: white;
    border: 1px solid var(--blue);
    padding: 2px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.prescription-image {
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin-left: 1rem;
}

.presc-location-button {
    position: absolute;
    right: 1.5rem;
    border: 0px;
    cursor: pointer;
    display: flex;
    padding: 2px;
    background-color: var(--white);
    z-index: 1;
}

.presc-place-order {
    display: block;
    background-color: var(--blue);
    border: 0px;
    padding: 1rem 2rem;
    /* width: 100px;
    height: 50px; */
    border-radius: .7rem;
    color: white;
    margin: 1.5rem auto;
    font-size: 1rem;
    cursor: pointer;
}

.required::after{
    content: ' *';
    color: var(--red);
}

/* Extra small devices (phones, 600px and down) */
@media screen and (max-width: 600px) {
    .prescription-container {
        padding: 0px 1rem;
    }
    .presc-form-row {
        flex-direction: column;
        align-items: initial;
        gap: .5rem;
    }

    .presc-location-button {
        bottom: .3rem;
    }
}