.alert-modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.alert-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000040;
}

.alert-container {
    position: relative;
    width: 250px;
    background-color: white;
    border-radius: 4px;
    padding: 1rem;
    box-shadow: 2px 2px 10px #ddd;
    text-align: end;
}

.alert-title {
    text-align: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 0.5rem;
}

.alert-desc {
    margin-top: 0.5rem;
    text-align: start;
    font-size: 1rem;
    word-break: break-word;
}

.alert-button {
    background-color: #11468F;
    border: 1px solid #11468F;
    border-radius: 4px;
    padding: 4px 8px;
    margin-top: 1rem;
    cursor: pointer;
    color: white;
}