.item-download{
    min-width: 250px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    padding: 1rem;
    gap: 1rem;
    box-shadow: 2px 2px 10px var(--border);
}
.app-logo{
    width: 80px;
    height: 80px;
    padding: .3rem;
    box-shadow: 1px 1px 10px var(--border);
}
.file-name{
    font-size: 1.1rem;
    font-weight: 600;
}
.download-btn{
    width: 80%;
    align-items: center;
    justify-content:space-around;
    background-color: var(--blue);
    border: 0;
    border-radius: 1rem;
    padding: .4rem 0;
    cursor: pointer;
    text-align: center;
}
.size-container{
    display: flex;
    gap: 1rem;
}
.apk-meta-text{
    font-size: .9rem;
    color: var(--grey-text);
    font-weight: 450;
}
.download-text{
    color: white;
    font-size: .9rem;
    margin-right: .4rem;
}